<template>
  <div>
    <Modal
      v-model="showModalCreate"
      title="Cria novo Componente Curricular"
      :enableClose="false"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label for="titulo">Título</label>
            <input
              id="titulo"
              v-model="input"
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <hr class="full-hr" />
      <div class="row">
        <div class="col-sm-12">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-classic"
              :disabled="!formIsValid()"
              @click.prevent="salvar"
            >
              Salvar
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2"
              @click="showModalCreate = false"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Componentes Curriculares</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Componentes Curriculares
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  class="col-md-3 d-flex justify-content-md-end"
                  v-if="createDisciplina"
                >
                  <!-- <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <button
                        class="btn btn-classic"
                        @click.prevent="openModalCreate"
                      >
                        <i class="fa fa-plus" /> NOVO
                      </button>
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                class="
                  block__classic
                  table
                  table__classic
                  table-striped
                  table-borderless
                  table-hover
                  text-center
                  mb-4
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Título</th>
                    <th scope="col" class="coluna-lixeira-restaurar" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id">
                    <td
                      class="text-left pl-4"
                      v-html="highlightMatches(item.text)"
                    />
                    <td>
                      <button
                        class="btn btn-sm btn-classic"
                        @click.prevent="restore(item.id)"
                      >
                        Restaurar
                      </button>
                      <!-- <button
                        class="btn btn-sm btn-danger m-2"
                        @click.prevent="deletar(item.id)"
                        v-if="createDisciplina"
                      >
                        <i class="far fa-trash-alt" />
                      </button> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import Swal from "sweetalert2";
import { checkUserHaveRole } from "@/tools";
import { constantes } from "@/constants";

export default {
  components: {
    MainTopBar,
    MainLeftSideBar,
    Modal: VueModal,
  },
  data() {
    return {
      showLeftMenu: true,
      showModalCreate: false,
      id: null,
      index: -1,
      input: null,
      items: [],
      filter: "",
      createDisciplina: true,
    };
  },
  computed: {
    filteredRows() {
      return this.items.filter((row) => {
        const searchTerm = this.filter.toLowerCase();
        const text = row.text.toLowerCase();
        return text.includes(searchTerm);
      });
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    this.loadData();

    if (checkUserHaveRole([constantes.ROLE_SUPER_ADMIN])) {
      this.createDisciplina = true;
    } else {
      this.createDisciplina = false;
    }
  },
  methods: {
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    openModalCreate() {
      this.showModalCreate = true;
      this.id = null;
      this.input = null;
      this.index = -1;
    },
    formIsValid() {
      if (this.input == "" || !this.input) {
        return false;
      } else {
        return true;
      }
    },
    salvar() {
      this.showModalCreate = false;
      if (this.formIsValid()) {
        this.saveServer();
      }
    },
    restore(id) {
      this.$api
        .put("disciplinas/" + id + "/recovery", {})
        .then((d) => {
          console.log(d);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Componente Curricular restaurado com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.items.splice(index, 1);
          setTimeout(function () {
            location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$root.$emit("Spinner::hide");
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Ocorreu um erro.",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
    deletar(id) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma remoção do componente curricular?",
        text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#999",
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$root.$emit("Spinner::show");
          this.$api.delete("disciplinas/" + id).then(() => {
            this.$root.$emit("Spinner::hide");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Componente Curricular removido com sucesso!",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(function () {
              location.reload();
            }, 1000);
          });
        }
      });
    },

    editar(index) {
      this.index = index;
      this.id = this.items[index].id;
      this.input = this.items[index].text;
      this.showModalCreate = true;
    },
    loadData() {
      this.$root.$emit("Spinner::show");

      this.$api.get("disciplinas").then((response) => {
        console.log(response);
        response.data.forEach((data) => {
          console.log(data);
          if (data.trashAt != null) {
            this.items.push(data);
          }
        });
        this.$root.$emit("Spinner::hide");
      });
    },
    saveServer() {
      this.$root.$emit("Spinner::show");
      var disciplina = {
        text: this.input,
      };

      if (this.index >= 0) {
        this.$api.put("disciplinas/" + this.id, disciplina).then(() => {
          this.$root.$emit("Spinner::hide");

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Componente Curricular alterado com sucesso!",
            showConfirmButton: false,
            timer: 2000,
          });

          // this.loadData();
          setTimeout(function () {
            location.reload();
          }, 1500);
        });
      } else {
        this.$api
          .post("disciplinas", disciplina)
          .then(() => {
            this.$root.$emit("Spinner::hide");

            Swal.fire({
              position: "center",
              icon: "success",
              title: "Componente Curricular cadastrado com sucesso!",
              showConfirmButton: false,
              timer: 2000,
            });

            // this.loadData();
            setTimeout(function () {
              location.reload();
            }, 1500);
          })
          .catch((error) => {
            this.$root.$emit("Spinner::hide");
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Ocorreu um erro!",
              showConfirmButton: false,
              timer: 1500,
            });
          });
      }
    },
  },
};
</script>

<style></style>
